'use client';

/* eslint-disable @next/next/no-sync-scripts */
// rule disabled because we need to load the scripts synchronously because of usercentrics
import { useEffect } from 'react';
import { useIsLeadManagementLanding } from 'utils/hooks/use-is-lead-management-landing';
import Script from 'next/script';

// constants
import { LEAD_MANAGEMENT_GTM_KEY } from 'app/head/scripts-server';

const ciamScriptSrc = (
  ciam: AppState['globals']['server'][string]['ciam'],
  locale: string,
): string => {
  return `${ciam.script_url}?apiKey=${ciam.api_key}&lang=${
    ciam.ciamOverwriteLangCode || locale.split('-')[0]
  }`;
};
const ciamReadyDispatch = () => {
  window.dispatchEvent(new CustomEvent('gigya.session.ready'));
};

interface ScriptsProps {
  globals: LanguageSpecificGlobals;
  server: ServerData;
}

export function Scripts({ globals, server }: Readonly<ScriptsProps>) {
  const isLeadManagementLanding = useIsLeadManagementLanding(server);

  useEffect(() => {
    if (globals?.ciam?.enabled === false) {
      document.querySelector('#gigya-script')?.remove();
    } else if (globals?.ciam?.enabled === true) {
      const script: HTMLScriptElement =
        document.querySelector('#gigya-script') ?? document.createElement('script');
      script.id = 'gigya-script';
      script.src = ciamScriptSrc(globals.ciam, globals.locale);
      script.onload = ciamReadyDispatch;
      if (!document.querySelector('#gigya-script')) {
        document.body.appendChild(script);
      }
    }
  }, [globals]);

  // GTM update for lead management landing
  // initial the GTM script is set in scripts-server.tsx
  // this is a workaround to update the GTM script in the head on client side when the page changes
  // this can be removed in half a year
  useEffect(() => {
    const gtmKeyToUse = isLeadManagementLanding ? LEAD_MANAGEMENT_GTM_KEY : globals?.gtmKey;
    const script = document.querySelector<HTMLScriptElement>('#gtm');

    if (script && script?.dataset?.gtmKey !== gtmKeyToUse) {
      // clone current inner html of script
      const innerScriptHtml = script.innerHTML;
      if (script.dataset?.gtmKey) {
        const newInnerScriptHtml = innerScriptHtml.replace(script.dataset.gtmKey, gtmKeyToUse);

        // remove existing gtm scripts
        document.querySelectorAll('script[gtm-script="true"]').forEach((script) => script.remove());

        // create new script
        const newScript = document.createElement('script');
        newScript.id = 'gtm';
        newScript.dataset.gtmKey = gtmKeyToUse;
        newScript.innerHTML = newInnerScriptHtml;

        // replace script
        script.parentNode?.replaceChild(newScript, script);
      }
    }
  }, [globals, isLeadManagementLanding]);

  return (
    <>
      {/* ciam/gigya */}
      {globals?.ciam?.enabled && (
        <Script
          id="gigya-script"
          src={ciamScriptSrc(globals.ciam, globals.locale)}
          onReady={ciamReadyDispatch}
        />
      )}
      {/* ciam/gigya end */}
    </>
  );
}
